var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container page-container"},[_c('div',{staticClass:"modal-header"},[_c('IconButton',{staticClass:"index-100",attrs:{"van-button":{ background: 'rgba(14, 16, 18, 0.16)' },"van-icon":{ name: 'arrow-left', size: 20, color: '#0E1012' }},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('h3',{staticClass:"modal-title"},[_vm._v("Отправить отчет")])],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"date-info__btn",class:{
      isError: _vm.isErrorChoosenDeal,
    }},[_c('van-popover',{attrs:{"trigger":"click","actions":_vm.actions},on:{"open":_vm.onOpen},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_vm._v(" "+_vm._s(_vm.choosenDeal ? _vm.choosenDeal : "Выберите дело")+" "),_c('img',{class:_vm.showPopover ? 'down' : 'up',attrs:{"src":require("@/assets/images/arrow_up.svg"),"alt":"","srcset":""}})]},proxy:true}]),model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}},[_c('div',{staticClass:"popup-filter-content"},_vm._l((_vm.deals),function(item,key){return _c('div',{key:key,staticClass:"deal-item",on:{"click":function($event){return _vm.chooseDeal(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])],1),_c('div',{staticClass:"form-date col",class:{
      isError: _vm.isErrorDate,
    },on:{"click":function($event){_vm.isShowCalendar = true}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.date),expression:"form.date"}],staticClass:"form-input",attrs:{"readonly":"","type":"text","placeholder":"Укажите дату"},domProps:{"value":(_vm.form.date)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "date", $event.target.value)}}}),_c('img',{attrs:{"src":require("@/assets/images/calendar.svg"),"alt":"","srcset":""}})]),_c('div',{staticClass:"form-date col",class:{
      isError: _vm.isErrorDesc,
    }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.text),expression:"form.text"}],class:[
        'form-textarea',
        {
          isErrorDesc: _vm.isErrorDesc,
        },
      ],attrs:{"placeholder":"Напишите..."},domProps:{"value":(_vm.form.text)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "text", $event.target.value)}}}),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/images/gallery-add.svg"),"alt":"","srcset":""},on:{"click":function($event){return _vm.chooseFile()}}})]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","name":"images","accept":"image/*"},on:{"change":_vm.handlePhoto}}),(_vm.imagesList && _vm.imagesList.length)?_vm._l((_vm.imagesList),function(item,key){return _c('van-image',{key:key,attrs:{"width":"100","height":"100","src":item}})}):_vm._e(),(_vm.form.photos && _vm.form.photos.length)?_c('div',{staticClass:"delete-picture"},[_c('van-image',{attrs:{"width":"100","height":"100","src":_vm.form.photos}}),_c('Button',{staticClass:"btn",attrs:{"button":{
        borderRadius: '100px',
        backgroundColor: '#FF3B30',
        color: '#fff',
        height: '54px',
        width: '54px',
        icon: 'trash.svg',
        iconPosition: 'left',
      }},nativeOn:{"click":function($event){return _vm.deletePhoto()}}})],1):_vm._e(),_c('div',{staticClass:"btn-holder"},[_c('Button',{staticClass:"btn icon-absolute-left",attrs:{"button":{
        title: _vm.$t('deal.progress.makeReport'),
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '40px',
        icon: 'send-2.svg',
        iconPosition: 'left',
        fontSize: '14px',
        paddingX: '15px',
      }},nativeOn:{"click":function($event){return _vm.handle()}}})],1),_c('van-dialog',{staticClass:"van-dialog-calendar",attrs:{"overlay":true,"show-confirm-button":false},model:{value:(_vm.isShowCalendar),callback:function ($$v) {_vm.isShowCalendar=$$v},expression:"isShowCalendar"}},[_c('CalendarReport',{attrs:{"isShow":_vm.isShowCalendar},on:{"onClose":function($event){_vm.isShowCalendar = false},"onConfirm":_vm.onConfirm}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }