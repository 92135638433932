<template>
  <div class="container page-container">
    <div class="modal-header">
      <IconButton
        class="index-100"
        :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }"
        :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
        @click="$router.go(-1)"
      />
      <h3 class="modal-title">Отправить отчет</h3>
    </div>

    <div
      class="date-info__btn"
      :class="{
        isError: isErrorChoosenDeal,
      }"
      v-click-outside="onClickOutside"
    >
      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="actions"
        @open="onOpen"
      >
        <template #reference>
          {{ choosenDeal ? choosenDeal : "Выберите дело" }}

          <img
            src="@/assets/images/arrow_up.svg"
            alt=""
            srcset=""
            :class="showPopover ? 'down' : 'up'"
          />
        </template>
        <div class="popup-filter-content">
          <div
            class="deal-item"
            v-for="(item, key) in deals"
            :key="key"
            @click="chooseDeal(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </van-popover>
    </div>

    <div
      class="form-date col"
      :class="{
        isError: isErrorDate,
      }"
      @click="isShowCalendar = true"
    >
      <input
        readonly
        v-model="form.date"
        type="text"
        class="form-input"
        placeholder="Укажите дату"
      />
      <img src="@/assets/images/calendar.svg" alt="" srcset="" />
    </div>
    <div
      class="form-date col"
      :class="{
        isError: isErrorDesc,
      }"
    >
      <textarea
        v-model="form.text"
        :class="[
          'form-textarea',
          {
            isErrorDesc,
          },
        ]"
        placeholder="Напишите..."
      />
      <img
        src="@/assets/images/gallery-add.svg"
        @click="chooseFile()"
        alt=""
        srcset=""
      />
    </div>

    <input
      type="file"
      ref="fileInput"
      name="images"
      @change="handlePhoto"
      style="display: none"
      accept="image/*"
    />
    <template v-if="imagesList && imagesList.length">
      <van-image
        width="100"
        height="100"
        v-for="(item, key) in imagesList"
        :key="key"
        :src="item"
      />
    </template>
    <div class="delete-picture" v-if="form.photos && form.photos.length">
      <van-image width="100" height="100" :src="form.photos" />
      <Button
        class="btn"
        @click.native="deletePhoto()"
        :button="{
          borderRadius: '100px',
          backgroundColor: '#FF3B30',
          color: '#fff',
          height: '54px',
          width: '54px',
          icon: 'trash.svg',
          iconPosition: 'left',
        }"
      />
    </div>

    <div class="btn-holder">
      <Button
        class="btn icon-absolute-left"
        @click.native="handle()"
        :button="{
          title: $t('deal.progress.makeReport'),
          color: 'rgba(14, 16, 18, 0.88)',
          borderRadius: '100px',
          backgroundColor: '#1D1B20',
          color: '#fff',
          height: '40px',
          icon: 'send-2.svg',
          iconPosition: 'left',
          fontSize: '14px',
          paddingX: '15px',
        }"
      />
    </div>

    <!-- <van-calendar
      lang="RU"
      v-model="isShowCalendar"
      @confirm="onConfirm"
      :show-confirm="false"
      :min-date="minDate"
      :max-date="maxDate"
      first-day-of-week="1"
    /> -->

    <van-dialog
      v-model="isShowCalendar"
      :overlay="true"
      :show-confirm-button="false"
      class="van-dialog-calendar"
      ><CalendarReport
        :isShow="isShowCalendar"
        @onClose="isShowCalendar = false"
        @onConfirm="onConfirm"
    /></van-dialog>
  </div>
</template>

<script>
import ApiReports from "@/api/reports";
import ApiDeals from "@/api/deals";

import { mapGetters } from "vuex";

import moment from "moment";
import IconButton from "@/components/Global/IconButton.vue";
import Button from "@/components/Global/Button.vue";
import CalendarReport from "@/components/Global/CalendarReport";

function deltaDate(input, days, months, years) {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0
      ).getDate()
    )
  );
}
export default {
  data() {
    return {
      isLoading: false,
      isHideBtn: true,
      isShowCalendar: false,
      isErrorDate: false,
      isErrorChoosenDeal: false,
      isErrorDesc: false,
      minDate: deltaDate(new Date(2020, 2, 30), 0, -1, 0),
      maxDate: new Date(),
      form: {
        date: null,
        text: null,
        photos: null,
      },
      imagesList: [],
      images: [],

      deals: [],
      choosenDeal: null,
      showPopover: false,

      actions: [],
    };
  },
  computed: {
    ...mapGetters("User", ["selfUser"]),
  },
  components: {
    IconButton,
    Button,
    CalendarReport,
  },
  methods: {
    chooseDeal(item) {
      this.choosenDeal = item.title;
      this.form.id = item.id;
      this.showPopover = false;
    },
    deletePhoto() {
      this.form.photos = [];
      this.$refs.fileInput.value = null;
    },
    handlePhoto(elem) {
      this.isLoading = true;
      axios
        .post(
          "https://media.vmeste.app/cors.php",
          { file: elem.target.files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Token": localStorage.getItem("token"),
            },
          }
        )
        .then((responce) => {
          console.log(responce);
          this.isLoading = false;
          if (responce.data.err != 0 && responce.data.err[0] != 0) {
            this.$notify({
              type: "danger",
              message: responce.data.err_msg[0],
            });
          } else {
            this.form.photos = responce.data.data;
          }
        });
    },
    chooseFile() {
      this.$refs.fileInput.click();
    },
    handle() {
      let err = 0;
      if (!this.form.date) {
        this.isErrorDate = true;
        err++;
      }
      if (!this.choosenDeal) {
        this.isErrorChoosenDeal = true;
        err++;
      }

      if (!this.form.text && !this.form.photos) {
        this.isErrorDesc = true;
        err++;
      }

      if (err == 0) {
        this.isLoading = true;

        ApiDeals.sendReports(this.form).then((responce) => {
          if (responce.err.length && responce.err[0] != 0) {
            this.$notify({
              type: "danger",
              message: responce.err_msg[0],
            });
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    onOpen() {
      setTimeout(() => {
        document
          .querySelector(".popup-filter-content")
          .parentNode.parentNode.classList.add("popup-filter");
      });
    },
    onClickOutside() {
      this.showPopover = false;
    },
    onConfirm(date) {
      this.isShowCalendar = false;
      this.form.date = moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    moment.locale("RU");
    ApiDeals.getDeals({
      id_user: this.selfUser.id,
    }).then((responce) => {
      this.deals = responce.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
}

.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-bottom: 24px;

  .modal-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Prata";
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
  }
}
.btn {
  width: 100%;
  margin-top: 40px;
}

.popup-filter-content {
  padding-left: 16px;
}

.deal-item {
  color: #0e1012;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 16px 16px 16px 0;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
  }
}

.van-popover__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 1px solid #d6d6d6;
  img.up {
    transform: rotate(180deg);
  }
}

.form-date {
  align-items: flex-start;
}

.form-textarea {
  min-height: 168px;
}

.btn-holder {
  margin-top: auto;

  .button {
    position: relative;
  }
}
</style>
